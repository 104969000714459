import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Sidebar(props) {
  const { description, social, title } = props;

  return (
    <Grid item xs={12} md={3}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.200" }}>
        <div style={{ display: "flex" }}>
          {social?.map((network) => (
            <Link
              display="block"
              variant="body1"
              href="#"
              key={network.link}
              sx={{ mb: 0.5 }}
              style={{
                marginRight: 5,
                textDecoration: "none",
                color: "#2196f3",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <network.icon />
                {/* <span>{network.name}</span> */}
              </Stack>
            </Link>
          ))}
        </div>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography style={{fontSize: 14}}>{description}</Typography>
      </Paper>
    </Grid>
  );
}

Sidebar.propTypes = {
  archives: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  description: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Sidebar;
